import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import EducationFeatures from '../../components/Industries/Education/EducationFeatures/EducationFeatures';
import EducationHero from '../../components/Industries/Education/EducationHero/EducationHero';
import EducationSolutions from '../../components/Industries/Education/EducationSolutions/EducationSolutions';
import IndustriesBusinessTypes from '../../components/Industries/IndustriesBusinessTypes/IndustriesBusinessTypes';
import IndustriesCarousel from '../../components/Industries/IndustriesCarousel/IndustriesCarousel';
import IndustriesIntegration from '../../components/Industries/IndustriesIntegration/IndustriesIntegration';
import IndustriesPaymentMethods from '../../components/Industries/IndustriesPaymentMethods/IndustriesPaymentMethods';
import { LocaleTypes } from '../../data/locales';

interface EducationPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const EducationPage: React.FC<EducationPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="industries.education.title" />
      <EducationHero />
      <EducationFeatures />
      <IndustriesBusinessTypes
        title="industries.education.business_types.title"
        subtitle="industries.education.business_types.subtitle"
        list={[
          'industries.education.business_types.list.type_1',
          'industries.education.business_types.list.type_2',
          'industries.education.business_types.list.type_3',
          'industries.education.business_types.list.type_4',
          'industries.education.business_types.list.type_5',
        ]}
      />
      <EducationSolutions />
      <IndustriesPaymentMethods/>
      <IndustriesIntegration />
      <IndustriesCarousel
        list={[
          'ecommerce',
          'tourism',
          'services',
        ]}
      />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default EducationPage;
