import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import './styles.scss';

const features = [
  {
    description: 'industries.education.benefits.benefit_1',
    number: 1,
  },
  {
    description: 'industries.education.benefits.benefit_2',
    number: 2,
  },
  {
    description: 'industries.education.benefits.benefit_3',
    number: 3,
  },
  {
    description: 'industries.education.benefits.benefit_4',
    number: 4,
  },
  {
    description: 'industries.education.benefits.benefit_5',
    number: 5,
  },
  {
    description: 'industries.education.benefits.benefit_6',
    number: 6,
  },
]

const EducationFeatures: React.FC = () => (
  <section className="py-layout-6 bg-light-gray-3">
    <div className="container">
      <div className="row">
        {
          features
            .sort((a,b) => a.number - b.number)
            .map(feature =>
              <div className="col-md-6" key={feature.number}>
                <div className="education-feature">
                  <div className="mr-5">
                    <div className="education-feature__number">
                      {feature.number}
                    </div>
                  </div>
                  <div className="education-feature__description">
                    <FormattedMessage id={feature.description}
                      values={defaultFormatValues}
                    />
                  </div>
                </div>
              </div>
            )
        }
      </div>
    </div>
  </section>
);

export default EducationFeatures;
